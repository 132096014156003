import { _API } from "./_API";
import { domain } from "./common";
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
export const GetProviderList = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/Provider/GetProviderList/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const SaveProvider = async (ProviderData) => {
    try{
        const mResponse = await _API(`${domain}/Provider/SaveProvider`,'post',{ProviderData})
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const DeleteRecord = async (ColID, TableName,ColName) => {
    try{
        const mResponse = await _API(`${domain}/Provider/DeleteRecord/${ColID}/${TableName}/${ColName}`,'delete')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}


