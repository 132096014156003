import axios from "axios";
export const _API = async (url, method, data) => {
    // try {
      let headers = {}; // Initialize headers as an empty object
      // Check if the data is FormData or a regular object
      if (data instanceof FormData) {
        headers = {
          'Content-Type': 'multipart/form-data',
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
        };
      }
      
      const response = await axios({
        url: url,
        method: method,
        data: data,
        headers: headers, // Use the headers determined above
      });
      if(response.status === 200){
       
      }else{
        
          console.error(response.data)
      }
      return response;
    // } catch (error) {
    //   console.log("dfsafsdfsdfs"+error)
    //   throw new Error(error)
    // }
  };