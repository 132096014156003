import * as actionTypes from './../Actions/types'
import { combineReducers } from 'redux'

const initialLoginState ={
    User_Data: null
}

const login_reducer = (state = initialLoginState, action) =>{
    switch(action.type){
        case actionTypes.LOGIN_USER : 
        return{
            ...state,
            User_Data: action.payload.loginData
        }
        default :
           return state
    }
}

const rootReducers = combineReducers({
    Login_User: login_reducer,
})
export default rootReducers