import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ServiceDetails = ({ onChangeSalesInvoice, SalesInvoiceData, CardOnFileErr,setCardOnFileErr }) => {
 const navigate = useNavigate()
  const style = {
    fontSize: 20
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          error={CardOnFileErr}
          label="Card On File"
          value={SalesInvoiceData.CardOnFile}
          onChange={(e) => {
            setCardOnFileErr(false)
            onChangeSalesInvoice('CardOnFile', e.target.value)
          }}
          // onChange={(e) => {
          //   const inputValue = e.target.value;

          //   // Check if the input value is empty or a number with a length of 4
          //   if (inputValue === '' || (/^\d+$/.test(inputValue) && inputValue.length <= 4)) {
          //     onChangeSalesInvoice('CardOnFile', inputValue);
          //   }
          // }}
          variant="outlined"
          fullWidth
          inputProps={{ style: style, maxLength: 4 }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Service Email"
          value={SalesInvoiceData.ServiceEmail}
          onChange={(e) => onChangeSalesInvoice('ServiceEmail', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          type="password"
          label="Service Password"
          value={SalesInvoiceData.ServicePassword}
          onChange={(e) => onChangeSalesInvoice('ServicePassword', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Account No"
          variant="outlined"
          value={SalesInvoiceData.Account}
          onChange={(e) => onChangeSalesInvoice('Account', e.target.value)}
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="CM Mac"
          value={SalesInvoiceData.CMMAC}
          onChange={(e) => onChangeSalesInvoice('CMMAC', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Pin"
          value={SalesInvoiceData.Pin}
          onChange={(e) => onChangeSalesInvoice('Pin', e.target.value)}
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            Total Balance
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            type="number"
            value={SalesInvoiceData.TotalBalance}
            onChange={(e) => onChangeSalesInvoice('TotalBalance', e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            inputProps={{ style: style }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            Total Bill
          </InputLabel>
          <OutlinedInput
           type="number"
            id="outlined-adornment-amount"
            value={SalesInvoiceData.TotalBill}
            onChange={(e) => onChangeSalesInvoice('TotalBill', e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            inputProps={{ style: style }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Discount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            type="number"
            value={SalesInvoiceData.Discount}
            onChange={(e) => onChangeSalesInvoice('Discount', e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            inputProps={{ style: style }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          type="date"
          value={SalesInvoiceData.CreatedDate}
          onChange={(e) => onChangeSalesInvoice('CreatedDate', e.target.value)}
          label="Created Date"
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6} xl={6}>
        <TextField
          id="outlined-basic"
          type="text"
          value={SalesInvoiceData.Issue}
          onChange={(e) => onChangeSalesInvoice('Issue', e.target.value)}
          multiline
          rows={4}
          label="Issue"
          variant="outlined"
          fullWidth
          InputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={12} sm={8} lg={9} xl={9}></Grid>
      <Grid item xs={12} sm={4} lg={3} xl={3}>
        <Button align="center"
          variant='contained'
          onClick={() => {
            navigate('/Sale')
          }}
          size='large'
          sx={{ fontWeight: 'bold', mt: 2, }}
          fullWidth
        >Back</Button>
      </Grid>
    </Grid>
  );
};

export default ServiceDetails;
