import React, { useState, useEffect } from "react";

import { Box, Button, Card, Container, Typography } from "@mui/material";
import ReactDOM from "react-dom"
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";import { connect} from 'react-redux'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SaleListTable from "./SaleListTable";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SaleList = ({CompID}) => {
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
  }, [CompID])
  return ReactDOM.createPortal (
    <div className="content">
          <Card sx={{ p: 4, mt: 7, display: 'flex', flexDirection: 'column' }}>
            <Link to='/Sale/0' style={{ marginBottom: '10px', alignSelf: 'flex-end' }}>
              <Button
                variant="contained"
                size="large"
              ><AddIcon />New Sale</Button>
            </Link>
            <Box >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
              <Tabs value={value} onChange={handleTabChange}  >
                <Tab label="Submit Sale" {...a11yProps(0)} />
                <Tab label="Schedule" {...a11yProps(1)} />
                <Tab label="Not Charge" {...a11yProps(2)} />
                <Tab label="Garbage" {...a11yProps(3)} />
              </Tabs>
            </Box>
              <CustomTabPanel value={value} index={0}>
                <SaleListTable SaleType = {'Submit sale'}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <SaleListTable SaleType = {'Schedule'}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <SaleListTable SaleType = {'Not Charge'} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <SaleListTable SaleType = {'Garbage'} />
              </CustomTabPanel>
            </Box>
          </Card>
        </div>,document.getElementById('PageContents')
  )
}

const mapStateToProps = state=>({
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
})
export default connect(mapStateToProps)(SaleList)