import React, { useEffect } from 'react'
import { connect} from 'react-redux'
import ReactDOM from "react-dom"
import { setLoginData } from '../../React_Redux/Actions'
import storage from 'redux-persist/lib/storage'
import { useNotification } from '../NotificationContext'
import { DeleteNotification, getNotifications } from '../../utils/NotificationAPI'
const Header = ({CompID}) => {
  const { notificationList, clearNotifications, setNotificationList } = useNotification();
  useEffect(() => {
    getData()
  }, [CompID])

  const getData = async () => {
    const res = await getNotifications(CompID)
    if (notificationList.length <= 0) {
      setNotificationList(res.Data)
    }
  }

  const deleteNotification = async () => {
    const res = await DeleteNotification(CompID)
    getData()
    clearNotifications()
  }

  return ReactDOM.createPortal  (
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-menu float-end mb-0">
      <li className="dropdown notification-list">
      <a
        className="nav-link dropdown-toggle arrow-none"
        data-bs-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <i className="dripicons-bell noti-icon" />
        {notificationList && notificationList.length > 0 && <span className="noti-icon-badge" />}
      </a>
      <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">
        {/* item*/}
        <div className="dropdown-item noti-title">
          <h5 className="m-0">
            <span className="float-end">
              <p onClick={deleteNotification} className="text-dark">
                <small style={{cursor:'pointer'}}>Clear All</small>
              </p>
            </span>
            Notification
          </h5>
        </div>
        {notificationList && <div style={{ maxHeight: 230 }} data-simplebar>
        {
          notificationList?.map(val => {
            return (
              <aside
                className="dropdown-item notify-item"
              >
                <div className="notify-icon bg-primary">
                  <i className="mdi mdi-comment-account-outline" />
                </div>
                <p className="notify-details" style={{ whiteSpace:'pre-line' }}>
                  {val.message}
                  <small className="text-muted">{val.currentTime}</small>
                </p>
              </aside>
            )
          }
          )
        }
      </div>
       }
        
        {/* All*/}
       <a
          href="javascript:void(0);"
          className="dropdown-item text-center text-primary notify-item notify-all" 
        ></a>
      </div>
    </li>
        <li className="dropdown notification-list">
          <a
            className="nav-link dropdown-toggle nav-user arrow-none me-0"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span className="account-user-avatar">
              <img
                src="assets/images/users/avatar-1.jpg"
                alt="user-image"
                className="rounded-circle"
              />
            </span>
            <span>
              <span className="account-user-name">Dominic Keller</span>
              <span className="account-position">Founder</span>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            
            <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div>
            <a
              className="dropdown-item notify-item"
              href={'/'}
              onClick={e=>{
                storage.removeItem('persist:root')
                localStorage.setItem("IsSignIn", false)}}
            >
              <i
                className="mdi mdi-logout me-1" />
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
      <button className="button-menu-mobile open-left">
        <i className="mdi mdi-menu" />
      </button>
    </div>,document.getElementById('topBar')
  )
}
const mapStateToProps = state=>({
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
})
export default connect(mapStateToProps)(Header)