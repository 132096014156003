import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom'
import ReactDOM from "react-dom"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Card, Typography,
  TextField, Autocomplete,
  Button, Switch, FormControlLabel,
} from "@mui/material";
import { connect} from 'react-redux'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from "@mui/icons-material/Visibility";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { CheckUserExistance, GetManagers, GetRoles, GetUsersWRTID, SaveUsers } from "../../utils/RegistrationApi";
import { GetCurrentDate, ToastMsg } from "../../utils/common";
;
const UserEntryPage = ({CompID}) => {
    const {UserID} = useParams()
    const navigate = useNavigate()
  const InitialState = {
    UserName: '',
    LoginID: '',
    Email: '',
    Password: '',
    PhoneNo: '',
    CreatedDate: GetCurrentDate(),
    IsActive: true,
  }
  const [UserData, setUserData] = useState(InitialState)
  const [Roles, setRoles] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [SelectedRole, setSelectedRole] = useState(null)
  const [Manager, setManager] = useState([])
  const [SelectedManager, setSelectedManager] = useState(null)
  const [isAgent, setisAgent] = useState(true)
  const [isRDDisabled, setisRDDisabled] = useState(false)
  const [IsPasswordChanged, setIsPasswordChanged] = useState(false)
  const [ErrFields, setErrFields] = useState({
    UserNameErr: false,
    LoginIDErr: false,
    EmailErr: false,
    PasswordErr: false,
    PhoneNoErr: false,
    SelectedRoleError: false,
    SelectedManagerError: false
  })
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onChangeUserData = (StateName, value) => {
    setUserData({
      ...UserData,
      [StateName]: value
    })
  }
  useEffect(() => {
    fetchRoles()
    fetchManagers()
    if (parseInt(UserID) > 0) {
      fetchUsers()
    }
  }, [UserID])
  const fetchRoles = async () => {
    const res = await GetRoles()
    if (res.status) {
      const mArr = res.Data
      setRoles(mArr)
    }
  }
  const SaveHandler = async () => {
    const isValid = validate()
    if (isValid) {
      const UserObject = {
        UserID: UserID,
        UserName: UserData.UserName,
        LoginID: UserData.LoginID,
        Email: UserData.Email,
        Password: UserData.Password,
        PhoneNo: UserData.PhoneNo,
        RoleID: SelectedRole ? SelectedRole.RoleID : 0,
        IsActive: UserData.IsActive ? 1 : 0,
        CreatedDate: UserData.CreatedDate,
        CompID: CompID,
        IsPasswordChanged: IsPasswordChanged,
        ManagerID: SelectedManager ? SelectedManager.UserID : 0
      }
      const res = await SaveUsers(UserObject)
      if (res.isExist) {
        ToastMsg(res.message)
      } else {
        if (res.status) {
          ToastMsg(res.message)
          navigate('/users')
          ResetFields()
        } else {
          ToastMsg(res)
        }
      }
    }
  }

  const fetchUsers = async () => {
    const res = await GetUsersWRTID(UserID)
    if (res.status) {
      const roles = await GetRoles()
      const mRoles = roles.Data
      const mArr = res.Data
      if (UserID) {
        const managerData = await GetManagers(CompID)
        const _manager = managerData.Data
        _manager.find((val) => {
          if (val.UserID === mArr[0].ManagerID) {
            setisAgent(false)
            setisRDDisabled(true)
            setSelectedManager(val)
          }
        })
      }
      mRoles.find((val) => {
        if (val.RoleID === mArr[0]?.RoleID)
          setSelectedRole(val)
      })

      setUserData({
        UserName: mArr[0].UserName,
        LoginID: mArr[0].LoginID,
        Email: mArr[0].Email,
        Password: mArr[0].Password,
        PhoneNo: mArr[0].PhoneNo,
        CreatedDate: mArr[0].CreatedDate,
        IsActive: mArr[0].IsActive === 1 ? true : false,
      })
    }
  }
  const ResetFields = () => {
    setUserData(InitialState)
    setSelectedRole(null)
  }
  const fetchManagers = async () => {
    if (UserID) {
      const res = await GetManagers(CompID)
      if (res.status) {
        setManager(res.Data)
      }
    }
  }
  const validate = () => {
    let isValid = true
    if (UserData.UserName.trim() === '') {
      setErrFields({ ...ErrFields, UserNameErr: true, LoginIDErr: false, EmailErr: false, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (UserData.LoginID.trim() === '') {
      setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: true, EmailErr: false, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (UserData.Email.trim() === '') {
      setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: true, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (UserData.Password.trim() === '') {
      setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: false, PasswordErr: true, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (UserData.PhoneNo.trim() === '') {
      setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: false, PasswordErr: false, PhoneNoErr: true, SelectedRoleError: false, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (!SelectedRole) {
      setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: false, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: true, SelectedManagerError: false })
      isValid = false
      return isValid
    }
    if (Manager.length > 0 && SelectedRole.RoleName === 'AGENT') {
      if (!SelectedManager) {
        setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: false, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: true })
        isValid = false
        return isValid
      }
    }
    setErrFields({ ...ErrFields, UserNameErr: false, LoginIDErr: false, EmailErr: false, PasswordErr: false, PhoneNoErr: false, SelectedRoleError: false, SelectedManagerError: false })
    return isValid
  }
  return ReactDOM.createPortal (
    <div className="content">
    <Box sx={{ flexGrow: 1, mt: 5 }}>
      <Grid container spacing={2} >
        <Card sx={{ width: '100%', p: 4, m: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Users</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <TextField
                error={ErrFields.UserNameErr}
                id="UserName"
                name="UserName"
                label="User Name"
                variant="outlined"
                value={UserData.UserName}
                onChange={(e) => {
                  setErrFields({ ...ErrFields, UserNameErr: false })
                  onChangeUserData('UserName', e.target.value)
                }}
                sx={{ fontSize: 24 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <TextField
                error={ErrFields.LoginIDErr}
                id="LoginID"
                name="LoginID"
                label="Login ID"
                variant="outlined"
                value={UserData.LoginID}
                onChange={(e) => {
                  onChangeUserData('LoginID', e.target.value)
                  setErrFields({ ...ErrFields, LoginIDErr: false })

                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <TextField
                error={ErrFields.EmailErr}
                id="Email"
                name="Email"
                label="Email"
                variant="outlined"
                value={UserData.Email}
                onChange={(e) => {
                  setErrFields({ ...ErrFields, EmailErr: false })
                  onChangeUserData('Email', e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={UserData.Password}
                  onChange={(e) => {
                    setErrFields({ ...ErrFields, PasswordErr: false })
                    onChangeUserData('Password', e.target.value)
                    if (parseInt(UserID) > 0)
                      setIsPasswordChanged(true)
                  }}
                  error={ErrFields.PasswordErr}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <img src='/visibleOff.svg' alt='icon' width={24} height={24} />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <TextField
                error={ErrFields.PhoneNoErr}
                id="PhoneNo"
                name="PhoneNo"
                value={UserData.PhoneNo}
                onChange={(e) => {
                  setErrFields({ ...ErrFields, PhoneNoErr: false })
                  onChangeUserData('PhoneNo', e.target.value)
                }}
                label="Phone No"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <Autocomplete
                disabled={isRDDisabled}
                clearOnEscape
                id="combo-box-demo"
                options={Roles}
                getOptionLabel={(option) => option.RoleName ? option.RoleName : ""}
                value={SelectedRole}
                onChange={(e, val) => {
                  if (val) {
                    setErrFields({ ...ErrFields, SelectedRoleError: false })
                    val.RoleName === 'AGENT' ? setisAgent(false) : setisAgent(true)
                    setSelectedRole(val);
                  } else {
                    setErrFields({ ...ErrFields, SelectedRoleError: true })
                    setSelectedRole(null);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    error={ErrFields.SelectedRoleError}
                    {...params}
                    label="Role"
                  />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <TextField
                id="CreatedDate"
                name="CreatedDate"
                type="date"
                value={UserData.CreatedDate}
                onChange={(e) => onChangeUserData('CreatedDate', e.target.value)}
                label="Created Date"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <Autocomplete
                disabled={isAgent}
                clearOnEscape
                id="combo-box-demo"
                options={Manager}
                getOptionLabel={(option) => option.UserName ? option.UserName : ""}
                value={SelectedManager}
                onChange={(e, val) => {
                  if (val) {
                    setErrFields({ ...ErrFields, SelectedManagerError: false })
                    setSelectedManager(val);
                  } else {
                    setErrFields({ ...ErrFields, SelectedManagerError: true })
                    setSelectedManager(null);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    error={ErrFields.SelectedManagerError}
                    {...params}
                    label="Manager"
                  />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <FormControlLabel sx={{ mt: 2 }} control={
                <Switch
                  checked={UserData.IsActive}
                  onChange={(e) => {
                    onChangeUserData('IsActive', e.target.checked)
                  }}
                />
              } label="Active" />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6} sm={3} lg={5} xl={5}></Grid>
            <Grid item xs={6} sm={3} lg={5} xl={5}></Grid>
            <Grid item xs={4} sm={3} lg={1} xl={1}>
              <LoadingButton
                onClick={SaveHandler}
                loading={false}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                size="large"
              >
                Save
              </LoadingButton>
            </Grid>
            <Grid item xs={4} sm={3} lg={1} xl={1}>
              <Link to={'/users'}>
                <Button variant="contained" size="large" fullWidth>Back</Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  </div>,document.getElementById('PageContents')
  )

}
const mapStateToProps = state=>({
  
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,

})
export default connect(mapStateToProps)(UserEntryPage)
