
import React, { createContext, useContext, useEffect, useState } from 'react';
import ting from "./ting.mp3";
import {socket} from '../utils/common'
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationList, setNotificationList] = useState([]);
  
  useEffect(()=>{
    
  },[socket])

  
  const addNotification = (notification) => {
    setNotificationList((prevList) => [...prevList, notification]);
    var audio = new Audio(ting);
    audio.play();
  };

  const clearNotifications = () => {
    setNotificationList([]);
  };

  return (
    <NotificationContext.Provider
      value={{ notificationList, addNotification, clearNotifications, setNotificationList }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
