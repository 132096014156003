import { _API } from "./_API";
import { domain } from "./common";



export const getNotifications = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/LiveNotification/getNotifications/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const SaveNotification = async (NotificationData) => {
    try{
        const mResponse = await _API(`${domain}/LiveNotification/SaveNotification`,'post',{NotificationData})
        return mResponse
    }catch(error){
        return error.message;
    }

}

export const DeleteNotification = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/LiveNotification/DeleteNotification/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

