
import React, { useState, useEffect } from 'react'
import {
  Autocomplete, Box,
  Button, Card, FormControl,
  FormControlLabel,
  Grid, InputAdornment,
  InputLabel, OutlinedInput,
  Popover,
  PopoverPaper,
  Switch, TextField, Typography,
  makeStyles
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const renderAccountNumber = (accNumber) => {
  const lastFourDigits = accNumber.slice(-4);
  const asterisksCount = Math.max(accNumber?.length - 4, 0);
  const asterisks = '*'.repeat(asterisksCount);
  const formattedAccountNumber = `${asterisks}${lastFourDigits}`;
  return formattedAccountNumber;
};

const columnsForCard = [
  {
    name: "NameOnCard",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "CVV",
    label: "CVV",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Expiry",
    label: "Expiry",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "CardNo",
    label: "Card No",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return renderAccountNumber(value)
      },
    }
  },
  {
    name: "BillingAddress",
    label: "Billing Address",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "OTC",
    label: "OTC",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "SelectedStatus",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? value.title : "";
      },
    }

  },
];

const columnsForCheque = [
  {
    name: "CheckingAccName",
    label: " Account Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "RN",
    label: "R/N",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Ac",
    label: "A/c",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "OTC",
    label: "OTC",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "SelectedStatus",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? value.title : "";
      },
    }

  },
];

const options = {
  filterType: 'checkbox',
  search: false,       // Hide search bar
  download: false,     // Hide download option
  selectableRows: 'none', // Hide checkboxes
  print: false,        // Hide print option
  viewColumns: false,  // Hide "View Columns" button
  filter: false,       // Hide filter dropdown menu
};
const PayDD = [
  { title: 'Card', value: 'Card' },
  { title: 'Cheque Acc', value: 'Cheque Acc' }
]
const StatusDD = [
  { title: 'Status pending', value: 'Status pending' },
  { title: 'Successfull', value: 'Successfull' },
  { title: 'Created', value: 'Created' },
  { title: 'Declined', value: 'Declined' },
  { title: 'Dispute', value: 'Dispute' },
  { title: 'Voided', value: 'Voided' },
  { title: 'Invalid Account', value: 'Invalid Account' },
  { title: 'Invalid Zip', value: 'Invalid Zip' }
]

const BillingDetails = ({ SaveHandler,
  onChangeSalesInvoice, CardData, setCardData,
  ChequeData, setChequeData, SalesInvoiceData }) => {
  const navigate = useNavigate()
  const style = {
    fontSize: 20
  }
  const [SelectedPaymentType, setSelectedPaymentType] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [ShowFields, setShowFields] = useState(true)
  const [IsCustomerAddress, setIsCustomerAddress] = useState(false)
  const [columns, setColumns] = useState(true);
  const initialCardStates = {
    SelectedPaymentType: null,
    NameOnCard: '',
    CVV: '',
    Expiry: '',
    CardNo: '',
    OTC: '',
    SelectedStatus: null,
    BillingAddress: '',
  }
  const initialChequeStates = {
    SelectedPaymentType: null,
    CheckingAccName: '',
    RN: '',
    Ac: '',
    OTC: '',
    SelectedStatus: null,
    BillingAddress: '',
  }
  const [SelectedCardData, setSelectedcardData] = useState(initialCardStates)
  const [SelectedChequeData, setSelctedChequeData] = useState(initialChequeStates)

  const onChangeCardData = (StateName, value) => {
    setSelectedcardData({
      ...SelectedCardData,
      [StateName]: value
    })
  }
  const onChangeChequeData = (StateName, value) => {
    setSelctedChequeData({
      ...SelectedChequeData,
      [StateName]: value
    })
  }
  const [ErrFields, setErrFields] = useState({
    PaymentTypeErr: false,
    NameOnCardErr: false,
    CVVErr: false,
    ExpiryDateErr: false,
    CardNoErr: false,
    OTCErr: false,
    StatusErr: false,
    AccNameErr: false,
    RNErr: false,
    AcErr: false,
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (ShowFields) {
      if (StatusDD.length > 0) {
        onChangeCardData('SelectedStatus', StatusDD[0])
      } else {
        onChangeCardData('SelectedStatus', null)
      }
    } else {
      if (StatusDD.length > 0) {
        onChangeChequeData('SelectedStatus', StatusDD[0])
      } else {
        onChangeChequeData('SelectedStatus', null)
      }
    }
  }, [StatusDD, ShowFields])

  const validate = () => {
    let isValid = true;
    if (ShowFields) {
      if (!SelectedPaymentType) {
        setErrFields({ ...ErrFields, PaymentTypeErr: true, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
        isValid = false;
        return isValid
      }
      if (SelectedCardData.NameOnCard.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: true, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
        isValid = false;
        return isValid
      }
      if (SelectedCardData.CVV.trim() === '' || SelectedCardData.CVV.length > 4 || SelectedCardData.CVV.length < 3) {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: true, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false });
        isValid = false;
        return isValid
      }
      if (SelectedCardData.Expiry.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: true, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
        isValid = false;
        return isValid;
      }
      if (!checkLuhnCardNo(SelectedCardData.CardNo) || SelectedCardData.CardNo.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: true, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
        isValid = false;
        return isValid
      }
      if (SelectedCardData.OTC.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: true, StatusErr: false })
        isValid = false;
        return isValid
      }
      if (!SelectedCardData.SelectedStatus) {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: true })
        isValid = false;
        return isValid
      }
    } else {
      if (!SelectedPaymentType) {
        setErrFields({ ...ErrFields, PaymentTypeErr: true, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
        isValid = false;
        return isValid
      }
      if (SelectedChequeData.CheckingAccName.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: true, RNErr: false, AcErr: false, })
        isValid = false;
        return isValid
      }
      if (SelectedChequeData.RN.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: true, AcErr: false, })
        isValid = false;
        return isValid
      }
      if (SelectedChequeData.Ac.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: true })
        isValid = false;
        return isValid
      }
      if (SelectedChequeData.OTC.trim() === '') {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: true, StatusErr: false })
        isValid = false;
        return isValid
      }
      if (!SelectedChequeData.SelectedStatus) {
        setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: true })
        isValid = false;
        return isValid
      }
    }
    setErrFields({ ...ErrFields, PaymentTypeErr: false, NameOnCardErr: false, CVVErr: false, ExpiryDateErr: false, CardNoErr: false, OTCErr: false, StatusErr: false, AccNameErr: false, RNErr: false, AcErr: false })
    return isValid
  }
  var checkLuhnCardNo = function (cardNo) {
    var s = 0;
    var doubleDigit = false;
    for (var i = cardNo.length - 1; i >= 0; i--) {
      var digit = +cardNo[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9)
          digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    return s % 10 == 0;
  }

  const addHandler = () => {
    let isValid = validate();
    if (isValid) {
      if (SelectedPaymentType?.value === 'Card') {
        if (Object.keys(SelectedCardData).length > 0) {
          setCardData([...CardData, { ...SelectedCardData, SelectedPaymentType }]);
          setSelectedcardData(initialCardStates);
        }
      } else if (SelectedPaymentType?.value === 'Cheque Acc') {
        if (Object.keys(SelectedChequeData).length > 0) {
          setChequeData([...ChequeData, { ...SelectedChequeData, SelectedPaymentType }]);
          setSelctedChequeData(initialChequeStates);
        }
      }
    }

  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} lg={3} xl={3}>
          <Autocomplete
            clearOnEscape
            id="combo-box-demo"
            value={SelectedPaymentType}
            options={PayDD}
            getOptionLabel={(option) => option.title ? option.title : ""}
            onChange={(e, val) => {
              if (val) {
                setSelectedPaymentType(val)
                setErrFields({ ...ErrFields, PaymentTypeErr: false });
                if (val.title === 'Cheque Acc') {
                  setShowFields(false)
                } else {
                  setShowFields(true)
                }
              } else {
                setSelectedPaymentType(null)
                setErrFields({ ...ErrFields, PaymentTypeErr: true });
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params} label="Pay via"
                error={ErrFields.PaymentTypeErr}
              />}
            fullWidth
          />
        </Grid>

        {
          ShowFields && ShowFields ? (
            <>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.NameOnCardErr}
                  id="outlined-basic"
                  label="Name on Card"
                  value={SelectedCardData.NameOnCard}
                  onChange={(e) => {
                    onChangeCardData('NameOnCard', e.target.value)
                    setErrFields({ ...ErrFields, NameOnCardErr: false });

                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.CVVErr}
                  id="outlined-basic"
                  label="CVV"
                  value={SelectedCardData.CVV}
                  onChange={(e) => {
                    onChangeCardData('CVV', e.target.value)
                    setErrFields({ ...ErrFields, CVVErr: false });
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3} style={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label="Expiry"
                    value={SelectedCardData.Expiry || null}
                    onChange={(newValue) => {
                      if (newValue === null) {
                        onChangeCardData('Expiry', ''); // Handle empty date
                      } else {
                        onChangeCardData('Expiry', newValue.format('MM-YYYY'));
                      }
                    }}
                    renderInput={(props) =>
                      <TextField
                        inputProps={{ style: style }}
                        error={ErrFields.ExpiryDateErr}
                        {...props}
                        variant="outlined"
                        fullWidth
                      />}
                    format="MM/YYYY"
                    views={['month', 'year']}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.CardNoErr}
                  id="outlined-basic"
                  label="Card No"
                  value={renderAccountNumber(SelectedCardData.CardNo)}
                  onChange={(e) => {
                    onChangeCardData('CardNo', e.target.value);
                    setErrFields({ ...ErrFields, CardNoErr: false });
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">OTC</InputLabel>
                  <OutlinedInput
                    error={ErrFields.OTCErr}
                    id="outlined-adornment-amount"
                    value={SelectedCardData.OTC}
                    onChange={(e) => {
                      onChangeCardData('OTC', e.target.value)
                      setErrFields({ ...ErrFields, OTCErr: false });
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="OTC"
                    inputProps={{ style: style }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <Autocomplete
                  clearOnEscape
                  id="combo-box-demo"
                  options={StatusDD}
                  getOptionLabel={(option) => option.title ? option.title : ""}
                  value={SelectedCardData.SelectedStatus}
                  onChange={(e, val) => {
                    if (val) {
                      onChangeCardData('SelectedStatus', val)
                      setErrFields({ ...ErrFields, StatusErr: false });

                    } else {
                      onChangeCardData('SelectedStatus', null)
                      setErrFields({ ...ErrFields, StatusErr: true });
                    }
                  }}
                  renderInput={(params) => <TextField
                    error={ErrFields.StatusErr}
                    {...params} label="Status"
                  />}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.AccNameErr}
                  id="outlined-basic"
                  label="CheckingAccName"
                  value={SelectedChequeData.CheckingAccName}
                  onChange={(e) => {
                    onChangeChequeData('CheckingAccName', e.target.value)
                    setErrFields({ ...ErrFields, AccNameErr: false });
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.RNErr}
                  id="outlined-basic"
                  label="R/n "
                  value={SelectedChequeData.RN}
                  onChange={(e) => {
                    onChangeChequeData('RN', e.target.value)
                    setErrFields({ ...ErrFields, RNErr: false });
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <TextField
                  error={ErrFields.AcErr}
                  id="outlined-basic"
                  label="A/c "
                  value={SelectedChequeData.Ac}
                  onChange={(e) => {
                    onChangeChequeData('Ac', e.target.value)
                    setErrFields({ ...ErrFields, AcErr: false });
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: style }}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">OTC</InputLabel>
                  <OutlinedInput
                    error={ErrFields.OTCErr}
                    id="outlined-adornment-amount"
                    value={SelectedChequeData.OTC}
                    onChange={(e) => {
                      onChangeChequeData('OTC', e.target.value)
                      setErrFields({ ...ErrFields, OTCErr: false });
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="OTC"
                    inputProps={{ style: style }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={3}>
                <Autocomplete
                  clearOnEscape
                  id="combo-box-demo"
                  options={StatusDD}
                  getOptionLabel={(option) => option.title ? option.title : ""}
                  value={SelectedChequeData.SelectedStatus}
                  onChange={(e, val) => {
                    if (val) {
                      onChangeChequeData('SelectedStatus', val)
                      setErrFields({ ...ErrFields, StatusErr: false });

                    } else {
                      onChangeChequeData('SelectedStatus', null)
                      setErrFields({ ...ErrFields, StatusErr: true });

                    }
                  }}
                  renderInput={(params) => <TextField
                    error={ErrFields.StatusErr}
                    {...params} label="Status"
                  />}
                  fullWidth
                />
              </Grid>
            </>
          )
        }

        <Grid item xs={6} sm={4} lg={3} xl={3}>
          <FormControlLabel sx={{ mt: 2 }} control={
            <Switch
              defaultChecked={IsCustomerAddress}
              value={IsCustomerAddress}
              onChange={(e) => {
                setIsCustomerAddress(e.target.checked)
                if (e.target.checked) {
                  onChangeCardData('BillingAddress', localStorage.getItem('Address1'))
                  onChangeChequeData('BillingAddress', localStorage.getItem('Address1'))
                }
              }}
            />
          } label="Is Customer Address" />
        </Grid>
        {
          ShowFields && ShowFields ? (
            <Grid item xs={9} sm={9} lg={9} xl={9}>
              <TextField
                id="outlined-basic"
                multiline
                rows={4}
                value={SelectedCardData.BillingAddress}
                onChange={(e) => onChangeCardData('BillingAddress', e.target.value)}
                label="Billing Address"
                variant="outlined"
                fullWidth
                inputProps={{ style: style }}
              />
            </Grid>
          ) : (
            <Grid item xs={9} sm={9} lg={9} xl={9}>
              <TextField
                id="outlined-basic"
                multiline
                rows={4}
                value={SelectedChequeData.BillingAddress}
                onChange={(e) => onChangeChequeData('BillingAddress', e.target.value)}
                label="Billing Address"
                variant="outlined"
                fullWidth
                inputProps={{ style: style }}
              />
            </Grid>
          )
        }
        <Grid item xs={3} sm={3} lg={3} xl={3}>
          <Button
            onClick={addHandler}
            variant='contained'
            fullWidth size='large'
            sx={{ mt: 5 }} >Add
          </Button>
        </Grid>
      </Grid>
      <div className='mt-2'>
        {
          ShowFields && ShowFields ? (
            <MUIDataTable
            title={"Billing Details"}
            data={CardData}
            columns={columnsForCard}
            options={options}
          />
          ):(
            <MUIDataTable
            title={"Billing Details"}
            data={ChequeData}
            columns={columnsForCheque}
            options={options}
          />
          )
        }
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={3} sm={3} lg={3} xl={3}>
            <Button
              variant='contained'
              onClick={() => {
                navigate('/Sale')
              }}
              size='large'
              sx={{ fontWeight: 'bold' }}
              fullWidth
            >Back</Button>
          </Grid>
          <Grid item xs={6} sm={4} lg={3} xl={3}>
            <Button
              variant='contained'
              onClick={() => {
                if (ChequeData.length > 0 || CardData.length > 0) {
                  SaveHandler('Submit sale')
                }
              }}
              size='large'
              sx={{ fontWeight: 'bold' }}
              fullWidth
            >Submit sale
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} lg={2} xl={2}>
            <Button
              variant='contained'
              onClick={(e) => {
                handleClick(e)
              }}
              size='large'
              sx={{ fontWeight: 'bold' }}
              fullWidth
            >Shedule</Button>
          </Grid>
          <Grid item xs={6} sm={4} lg={2} xl={2}>
            <Button
              variant='contained'
              size='large'
              onClick={() => {
                if (ChequeData.length > 0 || CardData.length > 0) {
                  SaveHandler('Not Charge')
                }
              }}
              sx={{ fontWeight: 'bold' }}
              fullWidth
            >Not Charge</Button>
          </Grid>
          <Grid item xs={6} sm={4} lg={2} xl={2}>
            <Button
              variant='contained'
              onClick={() => {
                if (ChequeData.length > 0 || CardData.length > 0) {
                  SaveHandler('Garbage')
                }
              }}
              size='large'
              sx={{ fontWeight: 'bold' }}
              fullWidth
            >Garbage</Button>
          </Grid>
        </Grid>
      </div>
      <React.Fragment>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: -30,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Box sx={{ width: "100%", p: 2 }}>
            <Grid item xs={6} sm={4} lg={3} xl={3} sx={{ m: 2 }} >
              <TextField
                id="outlined-basic"
                label="Scheduling Date"
                type='date'
                value={SalesInvoiceData.SchedulingDate}
                onChange={(e) => {
                  onChangeSalesInvoice('SchedulingDate', e.target.value)
                }}
                variant="outlined"
                fullWidth
                inputProps={{ style: style }}
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={3}>
              <Button
                variant='contained'
                onClick={() => {
                  if (ChequeData.length > 0 || CardData.length > 0) {
                    SaveHandler('Schedule')
                  }
                }}
                size='large'
                sx={{ fontWeight: 'bold', mt: 2 }}
                fullWidth
              >Save</Button>
            </Grid>
          </Box>
        </Popover>
      </React.Fragment>
    </>
  )
}



export default BillingDetails