import { _API } from "./_API";
import { domain } from "./common";
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL


export const GetUsersListOfMerchant = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/Merchant/GetUsersListOfMerchant/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const SaveMerchant = async (MerchantData) => {
    try{
        const mResponse = await _API(`${domain}/Merchant/SaveMerchant`,'post',{MerchantData})
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const GetAllMerchentList = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/Merchant/GetAllMerchentList/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}


export const GetMerchentWRTID = async (MarchentID,CompID) => {
    try{
        const mResponse = await _API(`${domain}/Merchant/GetMerchentWRTID/${MarchentID}/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}