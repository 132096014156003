
import React, { useState, useEffect } from 'react';
import { Card, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { connect} from 'react-redux'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator'
import { DeleteRecord, GetProviderList, SaveProvider } from '../utils/ProviderApi';
import { ToastMsg } from '../utils/common';


const roles = ['Market', 'Finance', 'Development'];

const randomRole = () => {
  return randomArrayItem(roles);
};
const initialRows = [
  {
    id: randomId(),
    name: randomTraderName(),

  },
  {
    id: randomId(),
    name: randomTraderName(),

  },
  {
    id: randomId(),
    name: randomTraderName(),

  },
  {
    id: randomId(),
    name: randomTraderName(),

  },
  {
    id: randomId(),
    name: randomTraderName(),

  },
];

const Provider = ({CompID}) => {
  const [ProviderData, setProviderData] = useState([])
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});

  useEffect(() => {
    // if(session?.user){
      fetchProviderData()
    // }
  }, [CompID])

  const fetchProviderData = async () => {
    const res = await GetProviderList(CompID)
    const mArr = res.Data
   const modifiedArray = mArr.map((val) => ({
      id: val.ProviderID,
      ProviderName: val.ProviderName,
      CompID: val.CompID
    }));
    setProviderData(modifiedArray)
  }


  function EditToolbar(props) {
    const { setProviderData, setRowModesModel } = props;
    const handleClick = () => {
      const id = randomId();
      setProviderData((oldRows) => [{ id, ProviderName: '', isNew: true ,CompID:0}, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };


    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async() => {
    const res = await DeleteRecord(id,'provider','ProviderID');
    if(res.status){
      ToastMsg(res.message)
      fetchProviderData()
    }
    // setProviderData(ProviderData.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = ProviderData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setProviderData(ProviderData.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async(newRow) => {
    if(newRow.ProviderName.trim()=== ''){
      ToastMsg('Provider Name is Required')
      return newRow
    }
    const res = await SaveProvider(newRow)
    if(res.status){
      ToastMsg(res.message)
    }
    fetchProviderData()
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns = [
    {
      field: 'ProviderName',
      headerName: 'Provider Name',
      width: 180, editable:
      true, flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div className="wrapper">
      <div className="content-page">
        <div className="content">
          <Card sx={{ width: '100%', p: 4, m: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Create New Provider</Typography>
            <Box
              sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rows={ProviderData}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setProviderData, setRowModesModel },
                }}
              />
            </Box>
          </Card>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state=>({
  
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,

})
export default connect(mapStateToProps)(Provider)

