
// import Swal from "sweetalert2";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';

// for localhost
export const domain = "http://66.29.134.156:4444/api"
export const socketDomain = "http://66.29.134.156:4444"
export const socket = io.connect(socketDomain)
// export const domain = "https://doctorshub.lthms.com:5534/api"
// export const socketDomain = "https://doctorshub.lthms.com:5534"
export const UploadsUrl = "LIVE UPLOADS URL GOES HERE"
export const environment = "development"
export const DEFAULT_IMAGE = "https://cdn-icons-png.flaticon.com/512/149/149071.png"
// for production

// export const environment = "production"


export const GetCurrentDate = () => {

  var date = new Date();

  return `${date.getFullYear()}-${((date.getMonth()) + 1) <= 9 ? '0' + ((date.getMonth()) + 1).toString() : (date.getMonth()) + 1}-${date.getDate() <= 9 ? '0' + (date.getDate()).toString() : date.getDate()}`
}

export const GetCurrentTime = () => {
  var today = new Date()
  return today.getHours() + ":" + today.getMinutes()
}

export const GetCurrentTimeAM_PM = () => {
  var date = new Date();
  var current_hour = date.getHours() <= 12 ? date.getHours() : date.getHours() % 12;
  var am_pm = date.getHours() <= 12 ? 'AM' : 'PM'
  var current_time = (current_hour <= 9 ? '0' + current_hour : current_hour) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()) + am_pm;
  return current_time
}
export const ThousandSeparater = (Num) => { //function to add commas to textboxes
  var x, x1, x2
  Num += '';
  Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
  Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
  x = Num.split('.');
  x1 = x[0];
  x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1))
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  return x1 + x2;
}


export const ReverseDate = (Date) => {
  return ((((Date).split('-')).reverse()).join('-'))
}


export const setDateFormat = (date) => {
  return date.getFullYear() + "-" + ((date.getMonth()) + 1) + "-" + date.getDate()
}
// export const msg = (text,type) =>{
//     Swal.fire({
//         title:'Validation',
//         text:`${text} `,
//         icon:`${type}`,
//     })
// }


// export const toastMsg = (text)=>{
//     // toast.configure()
//     toast.info(`${text}`, {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//     });
//   }

//   it will return specific days for the provided year say =>>==> getDatesForDayOfWeek(0,5) => all the sundays for next 5 years  
export const getDatesForDayOfWeek = (dayOfWeek, numYears = 1) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const dates = [];

  for (let i = 0; i < numYears; i++) {
    const yearToCheck = year + i;
    for (let month = 0; month < 12; month++) {
      const firstDayOfMonth = new Date(yearToCheck, month, 1);
      const lastDayOfMonth = new Date(yearToCheck, month + 1, 0);

      for (let date = firstDayOfMonth; date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
        if (date.getDay() === dayOfWeek) {
          dates.push(new Date(date));
        }
      }
    }
  }

  return dates;
}

export const WeekDays = [
  { value: 0, title: 'Sunday' },
  { value: 1, title: 'Monday' },
  { value: 2, title: 'Tuesday' },
  { value: 3, title: 'Wednesday' },
  { value: 4, title: 'Thursday' },
  { value: 5, title: 'Friday' },
  { value: 6, title: 'Saturday' },

]
export const numFormatter = (num) => {

  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num.toFixed(2); // if value < 1000, nothing to do
  }
  else {
    return num.toFixed(2);
  }
}


export const ToastMsg = (msg) => {
  toast.configure()
  toast.dark(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme:"light"
  });
}

