import { _API } from "./_API"
import { domain } from "./common";
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

export const GetAuthorizeDevices = async () => {
    try {
        const res = await _API(`${domain}/Devices/GetAuthorizeDevices`, 'get')
        return res.data
    } catch (error) {
        return error
    }
}

export const ApproveDevice = async (UserID, IsVerified,MACAddress) => {
    try {
        const res = await _API(`${domain}/Devices/ApproveDevice/${UserID}/${IsVerified}/${MACAddress}`, 'post')
        return res.data
    } catch (error) {
        return error
    }

}