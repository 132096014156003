
import React, { useState, useEffect } from "react";
import { connect} from 'react-redux'
import ReactDOM from "react-dom"
import { useParams } from 'react-router-dom'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    Card, Typography,
    TextField, Autocomplete,
    Button, Switch, FormControlLabel,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, Link } from "react-router-dom";
import { GetMerchentWRTID, GetUsersListOfMerchant, SaveMerchant } from "../../utils/MerchantApi";
import { GetCurrentDate, ToastMsg } from "../../utils/common";
const MarchentEntryPage = ({CompID}) => {
    const {MarchentID} = useParams()
    const navigate = useNavigate()
    const [Users, setUsers] = useState([])
    const [SelectedUser, setSelectedUser] = useState(null)
    const initialState = {
        MarchentName: '',
        DiscriptorName: '',
        DiscriptorBank: '',
        DailyLimit: '',
        IsActive: true,
        CreatedDate: GetCurrentDate()
    }
    const [MerchantData, setMerchantData] = useState(initialState)

    const onChangeMerchantData = (StateName, value) => {
        setMerchantData({
            ...MerchantData,
            [StateName]: value
        })
    }
    useEffect(() => {
        fetchUserData()
        if (parseInt(MarchentID) > 0) {
            fetchMerchent()
        }

    }, [CompID,MarchentID])

    const fetchMerchent = async () => {
        const res = await GetMerchentWRTID(MarchentID, CompID)
        if (res.status) {
            const mArr = res.Data
            const users = await GetUsersListOfMerchant(CompID)
            const _users = users.Data
            _users.map((val) => {
                if (val?.UserID === mArr[0]?.UserID) {
                    setSelectedUser(val)
                }
            })
            setMerchantData({
                MarchentName: mArr[0]?.MarchentName,
                DiscriptorName: mArr[0]?.DiscriptorName,
                DiscriptorBank: mArr[0]?.DiscriptorBank,
                DailyLimit: mArr[0]?.DailyLimit,
                IsActive: mArr[0]?.IsActive === 1 ? true : false,
            })
        }
    }

    const fetchUserData = async () => {
        const res = await GetUsersListOfMerchant(CompID)
        if (res.status) {
            const mArr = res.Data
            setUsers(mArr)
        }
    }
    const ResetFields = () => {
        setMerchantData(initialState)
        setSelectedUser(null)
    }
    const SaveHandler = async () => {
        if(MerchantData.MarchentName !== ""){
            const MerchantObject = {
                ...MerchantData,
                CompID: CompID,
                UserID: SelectedUser && SelectedUser.UserID ? SelectedUser.UserID : 0,
                MarchentID: parseInt(MarchentID),
                CreatedDate: GetCurrentDate(),
            }
            const res = await SaveMerchant(MerchantObject)
            if (res.status) {
                ToastMsg(res.message)
                ResetFields()
                navigate('/Marchent')
            }
        }else{
            ToastMsg("Marchent Name is required")
        }
        
    }
  return ReactDOM.createPortal (
    <div className="content">
    <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid container spacing={2} >
            <Card sx={{ width: '100%', p: 4, m: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Create New Merchent</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <TextField
                            id="MarchentName"
                            name="MarchentName"
                            label="Marchent Name"
                            variant="outlined"
                            value={MerchantData.MarchentName}
                            onChange={(e) => onChangeMerchantData('MarchentName', e.target.value)}
                            sx={{ fontSize: 24 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <TextField
                            id="DiscriptorName"
                            name="DiscriptorName"
                            label="Discriptor Name"
                            variant="outlined"
                            value={MerchantData.DiscriptorName}
                            onChange={(e) => onChangeMerchantData('DiscriptorName', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <TextField
                            id="DiscriptorBank"
                            name="DiscriptorBank"
                            label="Discriptor Bank"
                            variant="outlined"
                            value={MerchantData.DiscriptorBank}
                            onChange={(e) => onChangeMerchantData('DiscriptorBank', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <Autocomplete
                            clearOnEscape
                            id="users"
                            options={Users}
                            getOptionLabel={(option) => option.UserName ? option.UserName : ""}
                            value={SelectedUser}
                            onChange={(e, val) => {
                                if (val) {
                                    setSelectedUser(val);
                                } else {
                                    setSelectedUser(null);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="User" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <TextField
                            id="DailyLimit"
                            name="DailyLimit"
                            type="number"
                            value={MerchantData.DailyLimit}
                            onChange={(e) => onChangeMerchantData('DailyLimit', e.target.value)}
                            label="Daily Limit"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} xl={3}>
                        <FormControlLabel sx={{ mt: 2 }} control={
                            <Switch
                                checked={MerchantData.IsActive}
                                onChange={(e) => {
                                    onChangeMerchantData('IsActive', e.target.checked)
                                }}
                            />
                        } label="Active" />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={3} lg={5} xl={5}></Grid>
                    <Grid item xs={6} sm={3} lg={5} xl={5}></Grid>
                    <Grid item xs={4} sm={3} lg={1} xl={1}>
                        <LoadingButton
                            onClick={SaveHandler}
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            size="large"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={4} sm={3} lg={1} xl={1}>
                        <Link to='/Marchent'>
                            <Button variant="contained" size="large" fullWidth>Back</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </Box>
</div>,document.getElementById('PageContents')
  )
}

const mapStateToProps = state=>({
    CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
  })
export default connect(mapStateToProps)(MarchentEntryPage)