import { _API } from "./_API";
import { domain } from "./common";
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
export const SaveSaleInvoice = async (SaleinvoiceData) => {
    try{
        const mResponse = await _API(`${domain}/SaleInvoice/SaveSaleInvoice`,'post',{SaleinvoiceData})
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const GetMasterList = async (CompID,SaleType,CurrentPage,RoleID,UserID) => {
    
    try{
        const mResponse = await _API(`${domain}/SaleInvoice/GetMasterList/${CompID}/${SaleType}/${CurrentPage}/${RoleID}/${UserID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const ChangeStatus = async (SaleinvoiceData) => {
    try{
        const mResponse = await _API(`${domain}/SaleInvoice/ChangeStatus`,'post',{SaleinvoiceData})
        return mResponse.data
    }catch(error){
        return error.message;
    }

}