import { useEffect, useState } from 'react';
import './App.css';
import { socket } from './utils/common';
import { connect} from 'react-redux'
import Sidebar from './Components/layouts/sidebar';
import Dashboard from './Components/Dashboard/Dashboard';
import Header from './Components/layouts/Header';
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import UserList from './Components/User/UserList';
import UserEntryPage from './Components/User/UserEntryPage';
import Provider from './Components/Provider';
import MarchentList from './Components/Marchent/MarchentList';
import MarchentEntryPage from './Components/Marchent/MarchentEntryPage';
import SaleList from './Sale/SaleList';
import SaleInvoiceEntry from './Sale/SaleInvoiceEntry/SaleInvoiceEntry';
import AuthorizeDevicesList from './Components/AuthorizeDevicesList';
import Login from './Components/Login/Login';
import Protected from './Components/Protected'
import { useNotification} from './Components/NotificationContext'
import { SaveNotification } from './utils/NotificationAPI';

function App({RoleID,CompID,UserID}) {
  const {addNotification} = useNotification()
  const val = localStorage.getItem('IsSignIn');
  const IsSignIn = val === 'true' ? true : false;

  useEffect(() => {
    if (RoleID === 1 || RoleID === 2) {
      socket.on('userLogin', (data) => {
        addNotification(data);
        SaveNotificationHandler(data)
      });
      socket.on('PendingUser', (data) => {
        addNotification(data)
        SaveNotificationHandler(data)
      });
    }

    if(RoleID === 1 ||RoleID === 2 || RoleID === 3){
      socket.on('CreatedSale', (data) => {
        if (RoleID === 1 || RoleID === 2 || data.ManagerID === UserID) {
          addNotification(data)
          SaveNotificationHandler(data)
        }
 
      });
    }

    if (RoleID === 3) {
      socket.on('StatusChange', (data) => {
        if (data.ManagerID === UserID) {
          addNotification(data)
          SaveNotificationHandler(data)
        }
 
      });
    }
    return () => {
      socket.off('userLogin');
      socket.off('PendingUser')
      socket.off('CreatedSale')
      socket.off('StatusChange')
    };
  }, [socket,RoleID]);

  const SaveNotificationHandler = async (data) => {
    const NotificationData = {
      Notification: data.message,
      NotificationTime: data.currentTime,
      CompID:CompID
    }
    const res = await SaveNotification(NotificationData);
  }

  return (
    <>
    <BrowserRouter >
    <Sidebar />
    <Header />
    <Routes>
    <Route element={<Login />} path="/" /> 
    <Route element={<Protected isSignedIn={IsSignIn}><Dashboard /></Protected>} path="/Dashboard" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><UserList /></Protected>} path="/users" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><UserEntryPage /></Protected>} path="/users/:UserID" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><Provider /></Protected>} path="/Provider" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><MarchentList /></Protected>} path="/Marchent" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><MarchentEntryPage /></Protected>} path="/Marchent/:MarchentID" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><SaleList /></Protected>} path="/Sale" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><SaleInvoiceEntry /></Protected>} path="/Sale/:SaleInvoiceID" exact />
    <Route element={<Protected isSignedIn={IsSignIn}><AuthorizeDevicesList /></Protected>} path="/AuthorizeDevices" exact />
  </Routes>
  </BrowserRouter>
    </>
  );
}

const mapStateToProps = state=>({
  RoleID:state.Login_User.User_Data && state.Login_User.User_Data.RoleID,
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
  UserID:state.Login_User.User_Data && state.Login_User.User_Data.UserID,
})
export default connect(mapStateToProps)(App)
