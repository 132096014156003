import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { setLoginData } from '../../React_Redux/Actions';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import { Login as LoginAPI } from '../../utils/RegistrationApi';
import { ToastMsg } from '../../utils/common';
import useFingerprint from 'use-fingerprint';
import { GetAuthorizeDevices } from '../../utils/AuthorizeDevicesApi';
const Login = ({setLoginData}) => {
  const navigate = useNavigate()
  const [LoginID, setLoginID] = useState('')
  const [Password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [LoginIDErr, setLoginIDErr] = useState(false)
  const [PasswordErr, setPasswordErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [IsPasswordChanged, setIsPasswordChanged] = useState(false)
  const fingerprint = useFingerprint();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = () => {
    let isValid = true
    if (LoginID.trim() === '') {
      setLoginIDErr(true)
      ToastMsg('Please Enter RoleID')
      isValid = false
      return isValid
    }
    if (Password.trim() === '') {
      setPasswordErr(true)
      ToastMsg('Please Enter Password')
      isValid = false
      return isValid
    }
    setLoginIDErr(false)
    setPasswordErr(false)
    return isValid
  }

  const LoginHandler = async (e) => {
    e.preventDefault();
    let isValid = validate()
    if (isValid) {
      setLoading(true)
     const res = await LoginAPI(LoginID, Password, fingerprint)
      setLoading(false)
      if(res.status == 200){
        setLoginData(res.data.Data)
        localStorage.setItem("IsSignIn", true)
        navigate('/Dashboard')
        window.location.reload()
      }else{
        setLoginData(null)
        localStorage.setItem("IsSignIn", false)
        let Err = 'Invalid Credentials or User Is Not Active'
        alert(`your DeviceID is ${fingerprint}, please contact with admin to approve device`)
        ToastMsg(Err)
      }
      
    }
  }
  useEffect(()=>{
    storage.removeItem('persist:root')
    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('footer').hidden = true;
    document.getElementById('PageContents').hidden = true;
    document.getElementById('RSidebar').hidden = true;
  },[])
    const customEvent = async ()=>{
      const res = await GetAuthorizeDevices()
      console.log(res)
    }
  return  (
    <div className="loading authentication-bg" data-layout-config="{&quot;leftSideBarTheme&quot;:&quot;dark&quot;,&quot;layoutBoxed&quot;:false, &quot;leftSidebarCondensed&quot;:false, &quot;leftSidebarScrollable&quot;:false,&quot;darkMode&quot;:false, &quot;showRightSidebarOnStart&quot;: true}">
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                {/* Logo */}
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="index.html">
                    <span><img src="assets/images/logo.png" alt height={18} /></span>
                  </a>
                </div>
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
                    {/* <p className="text-muted mb-4">Enter your email address and password to access admin panel.</p> */}
                  </div>
                  <form onSubmit={LoginHandler}>
                    <div className="mb-3">
                      {/* <label htmlFor="emailaddress" className="form-label">Email address</label> */}
                      <TextField
                        error={LoginIDErr}
                        id="LoginID"
                        name="LoginID"
                        label="Login ID"
                        variant="outlined"
                        value={LoginID}
                        onChange={(e) => {
                          setLoginID(e.target.value)
                          setLoginIDErr(false)
                        }}
                        sx={{ fontSize: 24 }}
                        fullWidth
                      />
                    </div>
                    <div className="mb-3">
                      {/* <a href="pages-recoverpw.html" className="text-muted float-end"><small>Forgot your password?</small></a> */}
                      {/* <label htmlFor="password" className="form-label">Password</label> */}
                      <div className="input-group input-group-merge">
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            error={PasswordErr}
                            fullWidth
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={Password}
                            onChange={(e) => {
                              setPassword(e.target.value)
                              setPasswordErr(false)
                              setIsPasswordChanged(true)
                            }}
                            // error={Err.PasswordErr}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <img src='/visibleOff.svg' alt='icon' width={24} height={24} />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        {/* <div className="input-group-text" data-password="false">
                            <span className="password-eye" />
                          </div> */}
                      </div>
                    </div>
                    {/* <div className="mb-3 mb-3">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="checkbox-signin" defaultChecked />
                          <label className="form-check-label" htmlFor="checkbox-signin">Remember me</label>
                        </div>
                      </div> */}
                    <div className="mb-3 mb-0 text-center">
                      <button className="btn btn-primary" type="submit" disabled={loading}> {loading ? 'Authenticating...' : 'Login'}</button>
                    </div>
                    <div className="mb-3 mb-0 text-center">
                      <button className="btn btn-primary" type="button" onClick ={()=> customEvent()}> { 'Test'}</button>
                    </div>
                  </form>
                </div> {/* end card-body */}
              </div>
              {/* end card */}
             
              {/* end row */}
            </div> {/* end col */}
          </div>

          {/* end row */}
        </div>
        {/* end container */}
      </div>
      {/* end page */}
      {/* <footer className="footer footer-alt">
        2018 - 2021 © Hyper - Coderthemes.com
      </footer> */}
      {/* bundle */}
    </div>
  )
}
export default connect(null, { setLoginData})(Login)
