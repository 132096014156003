import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Add as AddIcon } from "@mui/icons-material";
import { Button, Card } from "@mui/material";
import { connect} from 'react-redux'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GetUsersList } from "../../utils/RegistrationApi";
import { ToastMsg } from "../../utils/common";
import EditIcon from '@mui/icons-material/Edit';
import ReactDOM from "react-dom"


const UserList = ({CompID}) => {
  const navigate = useNavigate()
  const [UserData, setUserData] = useState([])
  useEffect(() => {
      fetchUser()
  
  }, [CompID])

  const fetchUser = async () => {
    const res = await GetUsersList(CompID)
    if (res.status) {
      const mArr = res.Data
      setUserData(mArr)
    } else {
      ToastMsg(res.message)
    }
  }

  const columns = [
    {
      name: "UserID",
      options: {
        display: "excluded",
      }
    },
    {
      name: "UserName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "RoleName",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Action",
      label: "Edit",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <EditIcon
              onClick={() => { navigate(`/users/${tableMeta.rowData[0]}`) }}
            />
          );
        }
      }

    },
  ];
  const options = {
    search: false,       // Hide search bar
    download: false,     // Hide download option
    selectableRows: 'none', // Hide checkboxes
    print: false,        // Hide print option
    viewColumns: false,  // Hide "View Columns" button
    filter: false,       // Hide filter dropdown menu
  };
  return ReactDOM.createPortal(
    <div className="content">
          <Card sx={{ width: '100%', p: 4, m: 2, display: 'flex', flexDirection: 'column' }}>
            <Link to='/users/0'
              style={{ marginBottom: '10px', alignSelf: 'flex-end' }}>
              <Button
                variant="contained"
                size="large"

              ><AddIcon />New User</Button>
            </Link>
            <MUIDataTable
              title={"Users List"}
              data={UserData}
              columns={columns}
              options={options}
            />
          </Card>
        </div>,document.getElementById('PageContents')
  )
}
const mapStateToProps = state=>({
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
})
export default connect(mapStateToProps)(UserList)
// export default UserList