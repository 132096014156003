import { _API } from "./_API";
import { domain } from "./common";
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

export const Login = async (LoginID,Password, fingerprint) => {
    
    try{
        const mResponse = await _API(`${domain}/Users/Login/${LoginID}/${Password}/${fingerprint}`,'get')
        
        return mResponse
    }catch(error){
        return error.message;
    }

}

export const CheckUserExistance = async (LoginID) => {
    try{
        const mResponse = await _API(`${domain}/Users/CheckUserExistance/${LoginID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const SaveUsers = async (UserData) => {
    try{
        const mResponse = await _API(`${domain}/Users/SaveUsers`,'post',{UserData})
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const GetRoles = async () => {
    try{
        const mResponse = await _API(`${domain}/Users/GetRoles`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const GetUsersList = async (CompID) => {
    
    try{
        const mResponse = await _API(`${domain}/Users/GetUsersList/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

export const GetUsersWRTID = async (UserID) => {
    try{
        const mResponse = await _API(`${domain}/Users/GetUsersWRTID/${UserID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }
}

export const GetManagers = async (CompID) => {
    try{
        const mResponse = await _API(`${domain}/Users/GetManagers/${CompID}`,'get')
        return mResponse.data
    }catch(error){
        return error.message;
    }

}

