import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Card, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, OutlinedInput, Switch, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const CustomerDetails = ({ ProviderErr,setProviderErr, setProviderID, Provider, SelectedProvider, setSelectedProvider, SalesInvoiceData, setSalesInvoiceData, onChangeSalesInvoice }) => {

  const navigate = useNavigate()
  const style = {
    fontSize: 20
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <Autocomplete
          disablePortal
          id="Provider"
          options={Provider}
          getOptionLabel={(option) => option.ProviderName ? option.ProviderName : ""}
          value={SelectedProvider}
          onChange={(e, val) => {
            if (val) {
              setProviderErr(false)
              setSelectedProvider(val)
              setProviderID(val.ProviderID)
            } else {
              setSelectedProvider(null)
              setProviderErr(true)
            }
          }}
          fullWidth
          renderInput={(params) => <TextField
            error={ProviderErr}
            {...params} label="Provider"
          // InputProps={{ style: { fontSize: 30 } }}
          />}
        />

      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Customer Name"
          value={SalesInvoiceData.CustomerName}
          onChange={(e) => onChangeSalesInvoice('CustomerName', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />

      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Billing Phone No"
          value={SalesInvoiceData.BillingPhoneNo}
          onChange={(e) => onChangeSalesInvoice('BillingPhoneNo', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />

      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Associated Phone No"
          value={SalesInvoiceData.AssociatedPhoneNo}
          onChange={(e) => onChangeSalesInvoice('AssociatedPhoneNo', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Call Back Phone No"
          value={SalesInvoiceData.CallBackPhone}
          onChange={(e) => onChangeSalesInvoice('CallBackPhone', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Email"
          value={SalesInvoiceData.Email}
          onChange={(e) => onChangeSalesInvoice('Email', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />

      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="SSN"
          label="SSN"
          value={SalesInvoiceData.SSN}
          onChange={(e) => {
            onChangeSalesInvoice('SSN', e.target.value)
          }}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />

      </Grid>
      <Grid item xs={6} sm={4} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          type='Date'
          label="DOB"
          value={SalesInvoiceData.DOB}
          onChange={(e) => onChangeSalesInvoice('DOB', e.target.value)}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <TextField
          id="outlined-basic"
          label="Address 1"
          value={SalesInvoiceData.Address1}
          onChange={(e) => {
            onChangeSalesInvoice('Address1', e.target.value)
            localStorage.setItem('Address1', e.target.value)
          }}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <TextField
          id="outlined-basic"
          label="Address 2"
          multiline
          value={SalesInvoiceData.Address2}
          onChange={(e) => onChangeSalesInvoice('Address2', e.target.value)}
          rows={4}
          variant="outlined"
          fullWidth
          inputProps={{ style: style }}
        />
      </Grid>
      <Grid item xs={12} sm={8} lg={9} xl={9}></Grid>
      <Grid item xs={12} sm={4} lg={3} xl={3}>
        <Button align="center"
          variant='contained'
          onClick={() => {
            navigate('/Sale')
          }}
          size='large'
          sx={{ fontWeight: 'bold', mt: 2, }}
          fullWidth
        >Back</Button>
      </Grid>
    </Grid>
  )
}

export default CustomerDetails