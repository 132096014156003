import React ,{useState,useEffect} from "react"
import { connect} from 'react-redux'
import ReactDOM from "react-dom"
import MUIDataTable from "mui-datatables";
import { Card, Typography, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, Link } from "react-router-dom";
import { GetAllMerchentList } from "../../utils/MerchantApi";

const MarchentList = ({CompID}) => {
  const  [MerchentData, setMerchentData] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    // if(session?.user){
      fetchMerChentData()
    // }
  }, [CompID])

  const fetchMerChentData =async()=>{
    const res = await GetAllMerchentList(CompID)
    if(res.status){
      const mArr = res.Data
      setMerchentData(mArr)
    }
  }
  const columns = [
    {
      name: "MarchentID",
      options: {
      display:'excluded'
      }
    },
    {
      name: "MarchentName",
      label: "Merchent Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "DiscriptorName",
      label: "Discriptor Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "DiscriptorBank",
      label: "Discriptor Bank",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "DailyLimit",
      label: "Daily Limit",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Action",
      label: "Edit",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <EditIcon
            onClick={() => { navigate(`/Marchent/${tableMeta.rowData[0]}`)}} 
            />
          );
        }
      }

    },
  ];

  const options = {
    filterType: 'checkbox',
    search: false,       // Hide search bar
    download: false,     // Hide download option
    selectableRows: 'none', // Hide checkboxes
    print: false,        // Hide print option
    viewColumns: false,  // Hide "View Columns" button
    filter: false,       // Hide filter dropdown menu
  };

  return ReactDOM.createPortal (
    <div className="content">
    <Card sx={{ width: '100%', p: 4, m: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2, alignSelf: 'flex-start' }}>
       Marchent
      </Typography>
      <Link to='/Marchent/0'passHref style={{ marginBottom: '10px', alignSelf: 'flex-end' }}>
        <Button
          variant="contained"
          size="large"
        ><AddIcon />Add Merchant</Button>
      </Link>

      <MUIDataTable
        title={"Marchent List"}
        data={MerchentData}
        columns={columns}
        options={options}
      />
    </Card>
  </div>,document.getElementById('PageContents')
  );
};


const mapStateToProps = state=>({
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
})
export default connect(mapStateToProps)(MarchentList)