import React, { useState, useEffect } from "react";
import { connect} from 'react-redux'
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import JSZip from "jszip";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Card, Grid, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { ChangeStatus, GetMasterList } from "../utils/SaleInvoiceApi";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastMsg } from "../utils/common";

const renderAccountNumber = (accNumber) => {
  if (accNumber != null && accNumber != '') {
    const lastFourDigits = accNumber.slice(-4);
    const asterisksCount = Math.max(accNumber?.length - 4, 0);
    const asterisks = '*'.repeat(asterisksCount);
    const formattedAccountNumber = `${asterisks}${lastFourDigits}`;
    return formattedAccountNumber;
  }
};
const StatusDD = [
  { title: 'Status pending', value: 'Status pending' },
  { title: 'Successfull', value: 'Successfull' },
  { title: 'Created', value: 'Created' },
  { title: 'Declined', value: 'Declined' },
  { title: 'Dispute', value: 'Dispute' },
  { title: 'Voided', value: 'Voided' },
  { title: 'Invalid Account', value: 'Invalid Account' },
  { title: 'Invalid Zip', value: 'Invalid Zip' }
]
function Row(props) {
  const [IsDDDisabled, setIsDDdisabled] = useState(true)
  const { masterData, detailsData, fetchInvoiceData,RoleID} = props;
  const [open, setOpen] = useState(false);
  const [SelectedStatus, setSelectedStatus] = useState([])
  const displayStatus = () => {
    const successfulStatus = detailsData.find(data => data.Status === 'Successfull');
    if (successfulStatus) {
      return { status: successfulStatus.Status, OTC: successfulStatus.OTC, color: '#49fc03' };
    }
    const createdStatus = detailsData.find(data => data.Status === 'Created');
    if (createdStatus) {
      return { status: createdStatus.Status, OTC: createdStatus.OTC, color: '#03fcc6' };
    }
    const DisputeStatus = detailsData.find(data => data.Status === 'Dispute');
    if (DisputeStatus) {
      return { status: DisputeStatus.Status, OTC: DisputeStatus.OTC, color: '#52595D' };
    }

    const DeclinedStatus = detailsData.find(data => data.Status === 'Declined');
    if (DeclinedStatus) {
      return { status: DeclinedStatus.Status, OTC: DeclinedStatus.OTC, color: '#cc0000' };
    }

    const VoidedStatus = detailsData.find(data => data.Status === 'Voided');
    if (VoidedStatus) {
      return { status: VoidedStatus.Status, OTC: VoidedStatus.OTC, color: '#4863A0' };
    }

    const InvalidAccountStatus = detailsData.find(data => data.Status === 'Invalid Account');
    if (InvalidAccountStatus) {
      return { status: InvalidAccountStatus.Status, OTC: InvalidAccountStatus.OTC, color: '#1F6357' };
    }
    const InvalidZipStatus = detailsData.find(data => data.Status === 'Invalid Zip');
    if (InvalidZipStatus) {
      return { status: InvalidZipStatus.Status, OTC: InvalidZipStatus.OTC, color: '#1F6357' };
    }
    const PendingStatus = detailsData.find(data => data.Status === 'Status pending');
    if (PendingStatus) {
      return { status: PendingStatus.Status, OTC: PendingStatus.OTC, color: 'white' };
    }
    return { status: '', color: 'white' };
  }

  const { status, OTC, color } = displayStatus();

  useEffect(() => {
    if (detailsData.length > 0) {
      const initialStatus = detailsData.map(data => {
        const selectedStatus = StatusDD.find(status => status.title === data.Status);
        return selectedStatus || null;
      });
      setSelectedStatus(initialStatus);
    }
    if (RoleID === 1 || RoleID === 2) {
      setIsDDdisabled(false)
    }

  }, [detailsData]);



  for (let i = 0; i < detailsData.length; i++) {
    detailsData[i].CardNo = renderAccountNumber(detailsData[i]?.CardNo)
  }

  const UpdateStatus = async (mObj) => {
    const res = await ChangeStatus(mObj)
    if (res.status) {
      ToastMsg(res.message)
      fetchInvoiceData()
    }
  }

  const defaultProps = {
    options: StatusDD,
    getOptionLabel: (option) => option.title,
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, background: color }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <MinimizeIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {masterData.CustomerName}
        </TableCell>
        <TableCell align="center">{masterData.ProviderName}</TableCell>
        <TableCell align="center">{OTC}</TableCell>
        <TableCell align="center">{status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card sx={{ margin: 1, width: "100%", p: 4, m: 2 }}>
              <Typography variant="h6" gutterBottom component="div" >
              Order #{masterData.SaleInvoiceID}
              </Typography>
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "column", gap: 1, width: 200 }}>
                  <Typography align="left" variant="caption">
                    Customer information
                  </Typography>
                  <Typography variant="h7">{masterData.ServiceEmail}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", gap: 1, width: 400 }}>
                  <Typography align="left" variant="caption"> Address</Typography>
                  <Typography variant="h7">{masterData.Address1}</Typography>
                 
                </Grid>

              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>PaymentType</TableCell>
                    <TableCell>Card No</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Billing Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData.map((detailData, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {detailData.PaymentType === 'Cheque Acc' ? detailData.CheckingAccName : detailData.NameOnCard}
                        </TableCell>
                        <TableCell>{detailData.PaymentType}</TableCell>
                        <TableCell>{detailData.CardNo}</TableCell>
                        <TableCell width={200}>
                          <Autocomplete
                            {...defaultProps}
                            id="auto-complete"
                            autoComplete
                            disabled={IsDDDisabled}
                            includeInputInList
                            value={SelectedStatus[index] || null}
                            onChange={(e, val) => {
                              if (val) {
                                let mobj = {
                                  Status: val.title,
                                  PaymentType: detailData.PaymentType,
                                  SaleInvoiceID: detailData.SaleInvoiceID,
                                  SaleInvoiceDetailID: detailData.SaleInvoiceDetailID,
                                }
                                UpdateStatus(mobj)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Status" variant="standard" style={{ border: 'none' }} />
                            )}
                          />
                        </TableCell>
                        {/* <TableCell align="right">{detailData.Status}</TableCell> */}
                        <TableCell align="right">{detailData.BillingAddress}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};


const SaleListTable=({ SaleType ,RoleID,UserID,CompID})=> {
  const [detailsData,setDetailsData]=useState([])
  const [rowData, setRowData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    // if (session?.user) {
      fetchInvoiceData(1)
    // }
  }, [SaleType])

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(detailsData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = 'Sale.xlsx';
    saveAs(blob, fileName);
  };

  const fetchInvoiceData = async (CurrentPage) => {
    const res = await GetMasterList(CompID, SaleType, CurrentPage, RoleID, UserID)
    if (res.status) {
      const mArr = res.Data
    const _detailsData =  mArr.flatMap((item)=>item.DetailsData)
    setDetailsData(_detailsData)
      setRowData(mArr)
      setCurrentPage(res.pagination.current)
      setTotalPages(res.pagination.numberOfPages || 0)
    }
  }
  return (
    <div className="content">
    <Card sx={{ width: "100%", p: 4, m: 2, display: 'flex', flexDirection: 'column' }}>
    {
      RoleID === 1 && <Button sx={{ width: 200, alignSelf: 'flex-end' }} variant="contained" color="primary" onClick={exportToExcel}>
         Export Sale
       </Button>
     }
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Customer Name</TableCell>
              <TableCell align="center">Provider Name</TableCell>
              <TableCell align="center">OTC</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          {rowData.map((row, index) => (
            <Row key={index} masterData={row.MasterData} detailsData={row.DetailsData} fetchInvoiceData={fetchInvoiceData} RoleID={RoleID}  />
          ))}
        </Table>
      </TableContainer>
      <Pagination
        color='primary'
        count={totalPages}
        sx={{ mt: 2, alignSelf: 'flex-end' }}
        onChange={(e, page) => {
          if (page !== CurrentPage) {
            fetchInvoiceData(page)
            setCurrentPage(page);
          }
        }}
        showFirstButton
        showLastButton
      />
    </Card>
  </div>
  );
}

const mapStateToProps = state=>({
  RoleID:state.Login_User.User_Data && state.Login_User.User_Data.RoleID,
  CompID:state.Login_User.User_Data && state.Login_User.User_Data.CompID,
  UserID:state.Login_User.User_Data && state.Login_User.User_Data.UserID,
})
export default connect(mapStateToProps)(SaleListTable)



