import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import ReactDOM from "react-dom"
import { Card, Switch, Typography } from '@mui/material';
import { ApproveDevice, GetAuthorizeDevices } from '../utils/AuthorizeDevicesApi';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AuthorizeDevicesList = () => {
  const [DevicesData, setDevicesData] = useState([])
  const [isModalOpen, setisModalOpen] = useState(false);
  const [IsVeriedValue, setIsVeriedValue] = useState(false)
  const [RowUserID, setRowUserID] = useState('')
  const [MACAddress, setMACAddress] = useState('')
  const columns = [
    {
      name: "UserID",
      options: {
        display: "excluded",
      }
    },
    {
      name: "IsVerified",
      options: {
        display: "excluded",
      }
    },
    {
      name: "UserName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    // {
    //   name: "HostName",
    //   label: "Host Name",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },
    {
      name: "MACAddress",
      label: "Device ID",
      options: {
        filter: true,
        sort: false,
      }
    },
    //   {
    //   name: "Model",
    //   label: "Model",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },
    // {
    //   name: "Architecture",
    //   label: "Architecture",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   }
    // },
    {
      name: "IsVerified",
      label: "IsVerified",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          let IsVerified = tableMeta.rowData[1] === 1 ? true : false;
          return (
            <Switch
              checked={IsVerified}
              onChange={(e) => {
                setIsVeriedValue(e.target.checked)
                setRowUserID(tableMeta.rowData[0])
                setMACAddress(tableMeta.rowData[3])
                handleClickOpen()
              }}
            />
          );
        }
      }
    },

  ];


  const options = {
    filterType: 'checkbox',
    search: false,       // Hide search bar
    download: false,     // Hide download option
    selectableRows: 'none', // Hide checkboxes
    print: false,        // Hide print option
    viewColumns: false,  // Hide "View Columns" button
    filter: false,       // Hide filter dropdown menu
  };
  useEffect(() => {
    // if (session?.user) {
      fetchdevices()
    // }
  }, [ isModalOpen])

  const handleClickOpen = () => {
    setisModalOpen(true);
  };

  const handleClose = () => {
    setisModalOpen(false);
  };
  const fetchdevices = async () => {
    const res = await GetAuthorizeDevices()
    if (res.status) {
      const mArr = res.Data
      setDevicesData(mArr)
    }
  }

  const approveHandler = async () => {
    let IsVerified = IsVeriedValue ? 1 : 0
    const res = await ApproveDevice(RowUserID, IsVerified,MACAddress)
    if (res.status) {
      fetchdevices()
      handleClose()
    }

  }
  return ReactDOM.createPortal (
    <div className="content">
    <Card sx={{ width: '100%', p: 4, m: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, }}>
        Authorizes Devices
      </Typography>
      <MUIDataTable
        title={"Devices List"}
        data={DevicesData}
        columns={columns}
        options={options}
      />
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open form dialog
        </Button> */}
        <Dialog open={isModalOpen} onClose={handleClose}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {IsVeriedValue === true ? 'Are you sure you want to Verify this device ' : 'Are you sure you want to block this device '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={approveHandler}
            >Ok</Button>
            <Button onClick={handleClose}>Cancle</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Card>
  </div>,document.getElementById('PageContents')
  )
}

export default AuthorizeDevicesList