import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import CustomerDetails from "./CustomerDetails";
import ServiceDetails from "./ServiceDetails";
import BillingDetails from "./BillingDetails";
import { GetProviderList } from "../../utils/ProviderApi";
import { GetCurrentDate, ToastMsg } from "../../utils/common";
import { SaveSaleInvoice } from "../../utils/SaleInvoiceApi";
import { useNavigate } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SaleInvoiceEntry = ({CompID,UserID}) => {
  const { SaleInvoiceID } = useParams();
  const UserName = "Test";
  const navigate = useNavigate();
  const [Provider, setProvider] = useState([]);
  const [SelectedProvider, setSelectedProvider] = useState(null);
  const [ProviderID, setProviderID] = useState(0);
  const [ProviderErr, setProviderErr] = useState(false);
  const [CardOnFileErr, setCardOnFileErr] = useState(false);
  const [value, setValue] = useState(0);
  const InitialState = {
    SaleInvoiceID: 0,
    CustomerName: "",
    BillingPhoneNo: "",
    AssociatedPhoneNo: "",
    CallBackPhone: "",
    Email: "",
    SSN: "",
    DOB: GetCurrentDate(),
    Address1: "",
    Address2: "",
    CardOnFile: "",
    ServiceEmail: "",
    ServicePassword: "",
    Account: "",
    CMMAC: "",
    Pin: "",
    TotalBalance: 0,
    TotalBill: 0,
    Discount: 0,
    CreatedDate: GetCurrentDate(),
    Issue: "",
    CompID: CompID,

    SchedulingDate: GetCurrentDate(),
  };
  const [CardData, setCardData] = useState([]);
  const [ChequeData, setChequeData] = useState([]);
  const [SalesInvoiceData, setSalesInvoiceData] = useState(InitialState);
  const onChangeSalesInvoice = (StateName, value) => {
    setSalesInvoiceData({
      ...SalesInvoiceData,
      [StateName]: value,
    });
  };

  useEffect(() => {
    // if (session?.user) {

    fetchProvider();
    // }
  }, []);
  const ResetFields = () => {
    setSalesInvoiceData(InitialState);
    setSelectedProvider(null);
    setProviderID(0);
    setCardData([]);
    setChequeData([]);
  };
  const fetchProvider = async () => {
    // if (session.user) {
    const res = await GetProviderList(CompID);
    if (res.status) {
      const mArr = res.Data;
      setProvider(mArr);
    }
  };
  //   }

  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const handleTabChange = (event, newValue) => {
    if (value === 0 && !SelectedProvider) {
      setProviderErr(true);
      return;
    }
    if (value === 1 && !SalesInvoiceData.CardOnFile) {
      setCardOnFileErr(true);
      return;
    }
    setProviderErr(false);
    setCardOnFileErr(false);
    setValue(newValue);
  };
  const SaveHandler = async (SaleType) => {
    const _SalesInvoiceData = {
      SaleInvoiceMasterData: {
        ...SalesInvoiceData,
        ProviderID,
        SaleType,
        CompID: CompID,
        UserName: UserName,
        UserID: UserID,
      },
      SaleInvoiceDetailData: [...CardData, ...ChequeData],
    };
    const res = await SaveSaleInvoice(JSON.stringify(_SalesInvoiceData));
    if (res.status) {
      ToastMsg(res.message);
      ResetFields();
      navigate("/Sale");
    }
  };
  return ReactDOM.createPortal(
    <div className="content">
      <Card sx={{ width: "100%", p: 4, m: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Create New Sale
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Customer Details" {...a11yProps(0)} />
              <Tab label="Service Details" {...a11yProps(1)} />
              <Tab label="Billing Details" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CustomerDetails
              ProviderErr={ProviderErr}
              setProviderErr={setProviderErr}
              setProviderID={setProviderID}
              Provider={Provider}
              SelectedProvider={SelectedProvider}
              setSelectedProvider={setSelectedProvider}
              SalesInvoiceData={SalesInvoiceData}
              setSalesInvoiceData={setSalesInvoiceData}
              onChangeSalesInvoice={onChangeSalesInvoice}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ServiceDetails
              CardOnFileErr={CardOnFileErr}
              setCardOnFileErr={setCardOnFileErr}
              onChangeSalesInvoice={onChangeSalesInvoice}
              SalesInvoiceData={SalesInvoiceData}
              setSalesInvoiceData={setSalesInvoiceData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <BillingDetails
              CardData={CardData}
              setCardData={setCardData}
              ChequeData={ChequeData}
              setChequeData={setChequeData}
              SaveHandler={SaveHandler}
              SalesInvoiceData={SalesInvoiceData}
              onChangeSalesInvoice={onChangeSalesInvoice}
            />
          </CustomTabPanel>
        </Box>
      </Card>
    </div>,
    document.getElementById("PageContents")
  );
};

const mapStateToProps = (state) => ({
  CompID: state.Login_User.User_Data && state.Login_User.User_Data.CompID,
  UserID: state.Login_User.User_Data && state.Login_User.User_Data.UserID,
});
export default connect(mapStateToProps)(SaleInvoiceEntry);
